#three-showcase{

  background-color: #E2E2E2!important;
  padding-top: 20px;
  padding-bottom: 20px;

  p{
    margin: 0;
  }

  h4{

    padding: 20px;
    margin: 0;
  }

  img{

    width: 100%;
  }

  @media (max-width: $screen-sm-max) {
    .col{
      margin-bottom: 15px;
    }

    .col:last-of-type{

      margin-bottom: 0;
    }

  }


  @media (min-width: $screen-sm-max) {

    .col:first-of-type{

      padding-left: 0!important;
    }

    .col:nth-last-of-type(2){

      padding-left: 7.5px!important;
      padding-right: 7.5px!important;
    }


    .col:last-of-type{

      padding-right: 0!important;
    }

  }


}