
#mobile-menu-btn{
  font-size: 24px;

  padding: 15px;
  color: #424242;
  cursor: pointer;

  @media (min-width: $screen-sm-max) {

    display: none;
  }
}

.showSubmenu{
  color: #8a8a8a;
  margin-left: 5px;
  font-size: 14px;
}

#header-menu{
  background-color: #e2e2e2;
  padding-bottom: 35px;

  a{
    color: #8a8a8a;
    &:hover{
      text-decoration: none;
    }
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    font-size: 16px;
    &:after {
      content: ".";
      clear: both;
      display: block;
      visibility: hidden;
      height: 0;
    }
  }

  .menu{
    background-color: #fff;
    position: relative;
    display: none;

    @media (min-width: $screen-sm-max) {

      display: block;
    }


    &>li{
      padding: 10px 15px;
      border-bottom: transparent 3px solid;



      @media (min-width: $screen-sm-max) {

        float: left;

        &:hover{
          border-bottom: #B22222 3px solid;

          a{
            color: black!important;
          }

          .sub-menu>li>a{
            color: #fff;
          }

        }

        &.current_page_item{
          border-bottom: #B22222 3px solid;
        }


      }





    }


    .sub-menu{

      width: 100%;
      font-size: 16px;
      font-weight: 400;
      display: none;

      &>li{

        padding: 10px 15px;
      }


      @media (min-width: $screen-sm-max) {

        position: absolute;
        left: 0;
        background-color: #bdbdbd;

        top: 40px;

        &>li{
          display: inline-block;
          a{
            color: #fff;

            &:hover{
              text-decoration: underline;
              color: #fff;
            }
          }
        }

      }



    }

  }

}


