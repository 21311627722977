.clearfix:after {
  content: ".";
  clear: both;
  display: block;
  visibility: hidden;
  height: 0;
}

.white-bg{

  background-color: #fff;
}

