footer#colophon {
  clear: both;

  background-color: #fff;
  margin-top: 15px;
  border-top: 1px solid #e5e5e5;
  padding-bottom: 0;

  ul{
    list-style-type: none!important;
  }



}

#supplementary {
  max-width: 1000px!important;
  margin: 2em auto;
  padding: 0 15px!important;
  border-top: none!important;

  @media (max-width: $screen-sm-max) {

    padding: 0 7.6%!important;

  }
}


#supplementary .container    {
  max-width: 1000px!important;
  margin: 2em auto;
  padding: 0 !important;



}


footer #last-col{
  background: #444;
  color: #b9b9b9;
  padding: 25px 0;
}

footer .container{
  max-width: 1000px;
  margin: 0 auto;
  padding: 0;

  @media (max-width: $screen-sm-max) {

    padding: 0 7.6%;;

  }

}

#site-generator-menu{
  float: right;

  @media (max-width: $screen-xs-max) {

    float: none;
    margin-top: 15px;

  }

  a{
    color: inherit!important;
  }

  ul{

    list-style-type: none;
    padding: 0;
    margin: 0;

    li{

      display: inline-block;
      margin-left: 5px;
    }
  }
}


#first a{

  color: inherit!important;
  font-size: 16px;

  &:hover{

    text-decoration: none;
    border-bottom: 2px solid #B22222;
  }
}


#second ul{

  list-style-type: none;
}

#second a{
  color: inherit!important;
  font-size: 16px;
}

footer h3.widget-title {

  font-size: 1.6em!important;
  text-transform: none!important;
}

.footer-section-headline {
  text-align: center;
  font-size: 18px;
  margin-bottom: 40px;
  border-bottom: 1px solid #ddd;
  position: relative;
  height: 14px;
}
.footer-section-headline span {
  background-color: #fff;
  display: inline-block;
  padding: 0 10px;
  font-size: 18px;
  color: #666;
}


.footer-social-icons{
  text-align: center;
  bottom: 0;
  margin: 10px 0 30px 0;
}

.footer-social-icons div{
  display: inline-block;
  height: 50px;
  width: 50px;
  background: rgba(0,0,0,.5);
  color: #fff;
  background-clip: padding-box;
  border-radius: 100%;
  line-height: 50px;
  margin-right: 10px;
  text-align: center;
  font-size: 22px;
  cursor: pointer;
}

.footer-social-icons div:hover{
  color: #2f90c4;
  background: #cdcdcd;
}

footer input[type=text]{
  width: 97%;
}
footer input[type=email]{
  width: 97%;
}
footer textarea {
  width: 97%;
  max-height: 100px;
}