#full-showcase{

  background-color: #E2E2E2!important;
}

#full-showcase .row{

  background-color: #fff!important;
}

#full-showcase .col{

  padding: 0!important;
}

#full-showcase img{

  width: 100%;
}

#full-showcase p{
  margin: 0;
}

#full-showcase .col:nth-last-of-type(1){

  padding: 20px!important;
}
