/*
Theme Name: Das Wirbelwind Theme
Description: Das Wirbelwind Theme
Author: Lars Badke
Author URI: http://larsbadke.com
Template: twentyeleven
Version: 1.0
Tags:
*/


// Extra small screen / phone
//** Deprecated `$screen-xs` as of v3.0.1
$screen-xs:                  480px !default;
//** Deprecated `$screen-xs-min` as of v3.2.0
$screen-xs-min:              $screen-xs !default;
//** Deprecated `$screen-phone` as of v3.0.1
$screen-phone:               $screen-xs-min !default;

// Small screen / tablet
//** Deprecated `$screen-sm` as of v3.0.1
$screen-sm:                  768px !default;
$screen-sm-min:              $screen-sm !default;
//** Deprecated `$screen-tablet` as of v3.0.1
$screen-tablet:              $screen-sm-min !default;

// Medium screen / desktop
//** Deprecated `$screen-md` as of v3.0.1
$screen-md:                  992px !default;
$screen-md-min:              $screen-md !default;
//** Deprecated `$screen-desktop` as of v3.0.1
$screen-desktop:             $screen-md-min !default;

// Large screen / wide desktop
//** Deprecated `$screen-lg` as of v3.0.1
$screen-lg:                  1200px !default;
$screen-lg-min:              $screen-lg !default;
//** Deprecated `$screen-lg-desktop` as of v3.0.1
$screen-lg-desktop:          $screen-lg-min !default;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max:              ($screen-sm-min - 1) !default;
$screen-sm-max:              ($screen-md-min - 1) !default;
$screen-md-max:              ($screen-lg-min - 1) !default;

@import "base";
@import "header";
@import "footer";
@import "showcase-blocks";
@import "full-showcase";

html{
  background: #444;
  font-family: Droid Sans,Arial,sans-serif!important;
}

body{
  font-family: Droid Sans,Arial,sans-serif!important;
  padding: 0!important;
  margin-bottom: 0 !important;
  padding-top: 20px!important;
  background-color: #E2E2E2!important;
  font-size: 16px!important;

  @media (max-width: $screen-sm-max) {

    padding-top: 0!important;

  }
}

h1, h2, h3, h4, h5, h6{
  color: #666!important;
}

h3{
  font-size: 24px!important;
  font-weight: bold!important;
  text-transform: none!important;
}

.entry-content a{

  color: #B22222;

  &:hover{
    color: #B22222;
  }
}

#page{
  margin-top: 0!important;
  margin-bottom: 0!important;
  padding-bottom: 0!important;
  padding-top: 0!important;
}

#main:after {
  content: ".";
  clear: both;
  display: block;
  visibility: hidden;
  height: 0;
}

.wpcf7-form{

  input[type="text"], input[type="email"]{
    width: 98%!important;
  }

  input[type="submit"]{
    background-color: #B22222;
    color: #fff;
    border: none;
    padding: 5px 20px;
  }
}

.error404{

  padding: 15px;

  .entry-content{

    min-height: 200px;

    .widget{
      display: none!important;
    }

    #searchform{
      margin: 0!important;
    }
  }


}



#full-page-content {

  /*background-color: #0a0a0a;*/

  margin-top: -36px;

  div.content {

    padding: 20px !important;
  }

  .post-edit-link{
    display: none;
  }

  header.entry-header{
    display: none;
  }

  .entry-content{
    width: 100%!important;
    padding: 0!important;
  }

  article{
    padding: 0!important;
  }


}


#content{


  @media (min-width: $screen-sm-max) {

    margin: 0 0!important;

  }

  article{

    padding-top: 0!important;
  }
}


p img{
  margin-top: 0!important;
}


.social-icons{
  position: absolute;
  bottom: 0;
  margin: 10px;
}

.social-icons div{
  display: inline-block;
  height: 35px;
  width: 35px;
  background: rgba(0,0,0,.5);
  color: #fff;
  background-clip: padding-box;
  border-radius: 100%;
  line-height: 35px;
  margin-right: 5px;
  text-align: center;
  font-size: 16px;
  cursor: pointer;
}

.social-icons div:hover{
  color: #2f90c4;
  background: #fff;
}



